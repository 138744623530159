// Testimonials.js
import React, { useState, useEffect } from 'react';

import styles from "./Testimonials.module.css"
// import account from "../Assets/icons/account.png"

const Testimonials = ({ testimonials }) => {
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const [animation, setAnimation] = useState("fadeIn"); // start with fadeIn for initial render

  useEffect(() => {
    if (animation === "fadeIn") return; // don't do anything if it's fading in

    const next = (activeTestimonial + 1) % testimonials.length;

    const timeoutId = setTimeout(() => {
      setActiveTestimonial(next);
      setAnimation("fadeIn");
    }, 500); // change testimonial after fadeOut has completed

    return () => clearTimeout(timeoutId);
  }, [animation, activeTestimonial, testimonials]);

  useEffect(() => {
    const id = setTimeout(() => {
      setAnimation("fadeOut");
    }, 2500); // start fading out after 2.5 seconds

    return () => clearTimeout(id);
  }, [activeTestimonial]);


  return (
    <div className={styles.wrap}>
      {testimonials && testimonials.map((testimonial, index) => (
        <div
          key={index}
          className={`${styles.testimonial} ${index === activeTestimonial ? styles[animation] : styles.notActive}`} >
            
          <p className={styles.name}>
            {testimonial.name} :
           
            <b className={styles.message}>
              "{testimonial.message}"
            </b>

          </p>

        </div>
      ))}
    </div>
  );
};

export default Testimonials;

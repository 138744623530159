const testimonials = [
  {
    name: 'William',
    message: 'Love these pictures!!!!!!'
  },
  {
    name: 'Lea',
    message: 'Just scored the perfect christmas gift, thank you!'
  },
  {
    name: 'Sunny',
    message: 'So fun and realistic!'
  },
  {
    name: 'Bryan',
    message: 'I think this tech is so cool and your service is amazing!'
  },
  {
    name: 'Miroslava',
    message: 'They are so beautiful!'
  },
  {
    name: 'Maria',
    message: "I'll turn this into a poster!"
  },
  {
    name: 'Stanislava',
    message: 'So professional looking!'
  },
  {
    name: 'Alon',
    message: 'Looks awesome!'
  },
  {
    name: 'Audrey',
    message: 'Thanks again they all love your AI site'
  }

]
export default testimonials
import React from "react"
import styles from "./Samples.module.css"

// import sample1 from "../Assets/samples/sample1.webp"
// import sample2 from "../Assets/samples/sample2.webp"
import sample3 from "../Assets/samples/sample3.webp"
import sample4 from "../Assets/samples/sample4.webp"
import downArrow from "../Assets/icons/down-arrow-2.png"
// import downArrow2 from "../Assets/icons/down-arrow-3.png"

export default function Samples({ index }) {
  //check index value and set petType accordingly
  
  // const petType = index === 0 || index === 2 || index === 4 || index === 6 ? "Dog" : index === 8 ? "Pet" : "Cat";


  [sample3, sample4, downArrow].forEach((image) => { new Image().src = image });

  return (
    <div className={styles.wrapper}>
      {/* <h3 className={styles.h4}>Your Pet</h3> */}

      <span className={styles.span}>

        {/* <img className={styles.sampleImage} src={sample2} alt="pet dog input for furry art" /> */}
        <img className={styles.sampleImage} src={sample3} alt="pet dog input for furry art" />

        {/* <img className={styles.sampleImage} src={sample1} alt="pet cat input for furry art" /> */}
        <img className={styles.sampleImage} src={sample4} alt="pet cat input for furry art" />
        {/* {petType === "Pet" && <div className={styles.sampleImage}/>} */}
        {/* {petType === "Pet" && <div className={styles.sampleImage} />} */}

        <img className={styles.arrowIcon} src={downArrow}
          alt={"arrow pointing down to furry art portraits from pet input"} />
      </span>

    </div >
  )
}

import { Link } from "react-router-dom"


const faqs = [
  {
    question: 'What does it?',
    answer: "AI Pet Photos is an AI Image Generator for cats and dogs - you can use to create photorealistic pet portraits or any other image of your pet, e.g. in funny costumes, poses or copycat any existing pet image with your pet in it!"
  },
  {
    question: 'How does AI Pet Photos work?',
    answer: "AI Pet Photos generator learns what your pet looks like. The AI then is given precise instructions on what kind of images to generate using our AI image templates and we notify you when it's done. You can then generate your own images with your pet! You can copycat a portrait or any image that has a pet present and our generator will spit out a copy with your pet in it!"
  },
  
  {
    question: 'Is my pet’s photo safe?',
    answer: 'Your pet’s photos are only used to train the generateive AI what your pet looks like and are then deleted from our service.'
  },
  {
    question: 'Can I use photos from my mobile device?',
    answer: 'Yes, you can upload photos directly from your mobile device, tablet or computer.'
  },
  {
    question: 'What if I don’t like the artwork?',
    answer: 'AI Pet Photos is a fun way to generate entertaining images of your pet. This is an experimental service and is still in beta. We are working on improving our processes, but generative AI is a new and developing field.'
  },
  {
    question: 'How long does it take to generate the images?',
    answer: 'It can take up to an hour you will be notified with an email, but usually about 20 minutes.'
  },
  {
    question: 'Can I get a refund?',
    answer: 'Generative AI uses a lot of expensive computing power and as such we cannot offer refunds at this time.'
  },
  {
    question: 'Will it look like my pet?',
    answer: 'The AI is trained on your pet’s photos and will generate images based on your photos. Most images will resemble your pet strongly.'
  },
  {
    question: 'Is my dog too large or too small?',
    answer: 'Most styles are very adaptable to different breeds in our initial tests. We are working on improving our processes, but generative AI is a new and developing field. We look forward to your feedback and apprecite your patience.'
  },
  {
    question: 'Do you offer printing and framing?',
    answer: 'We are focused on the software, but at the bottom of the website we have some popular options depending on where you are based.'
  },
  {
    question: 'How do I apply a discount code?',
    answer: 'At the checkout page, after you have filled out your pet details, tap on Add promotion code button - below the Subtotal and above the Total -  enter it in the field, click Apply.'
  },
  {
    question: 'What are the new custom manual (DIY) portraits?',
    answer: 'Super easy way to make any portrait of your pet instead of just enjoying our templates! Simply select an image (that has any cat or dog), describe the image in your words and submit! Our system will generate an image substituting the pet in the image with yours! These are not 4K resolution yet, but are working on making them soon.'
  },
  
  {
    question: 'Can I give it as a gift?',
    answer: (
      <>Yes! Many of our users get Vouchers as Pet Memorial Gifts or just for any special occasion! Please visit our <Link to="/pet-gift-voucher-portraits">gift voucher page {'>'}</Link >
      </>
    )
  },


];

export default faqs
import React, { useRef, useState, useEffect } from "react"
import styles from "./NavbarBottom.module.css"
import { NavLink, useLocation } from "react-router-dom"
import arrowIcon from "../../Assets/icons/next-2.png";

// import { NavHashLink } from 'react-router-hash-link';
// import mailIcon from "../../Assets/icons/email.png"
import axios from "axios"
import { baseUrl } from '../../config'


export default function NavbarBottom() {
  const location = useLocation();

  // const headerRef = useRef(null);
  const [feedback, setFeedback] = useState(false);
  const [feedbackForm, setfeedbackForm] = useState({
    message: "",
    email: "",
    messageType: "feedback"
  });

  const [buttonLabel, setButtonLabel] = useState("SEND");


  async function submitFeedback() {
    setButtonLabel("SENDING...");
    //validate feedbackForm
    if (!feedbackForm.message) {
      alert("Please enter a message");
      setButtonLabel("SEND");
      return;
    }
    if (!feedbackForm.email) {
      alert("Please enter an email");
      setButtonLabel("SEND");
      return;
    }
    if (feedbackForm.email && feedbackForm.email.includes('@saas-space.com')) {
      alert("You've been block for Spam. Go away please.");
      return;
    }
    //validate email
    const email = feedbackForm.email;
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email");
      setButtonLabel("SEND");
      return;
    }


    try {
      const response = await axios.post(`${baseUrl}/feedback`, feedbackForm);

      if (response.status === 201) {
        // Handle success (e.g., show a thank you message)
        setButtonLabel("Sent! Thanks! This window will close in 3s.");
        setTimeout(() => {
          setFeedback(false);
        }, 3000);
      } else {
        setButtonLabel("Something went wrong. Please try again.");
      }

    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  }

  function handleButton() {
    setFeedback(!feedback);
  }


  return (
    <div className={(location.pathname === '/' || location.pathname === '/pet-portraits-gallery/1') ? styles.navWrapNormal : styles.navWrapNormalOld}>



      {feedback ?
        <label className={styles.labelWrap}> Your Message
          <textarea className={styles.messInput}
            maxLength="150"
            value={feedbackForm.message}
            onChange={(e) => setfeedbackForm({ ...feedbackForm, message: e.target.value })}
          />
          {feedbackForm?.message?.length > 0 && <small>{`Remaining characters: ${150 - feedbackForm?.message?.length}`}</small>}
          <label className={styles.labelWrap2}> Your Email
            <input className={styles.messInput}
              maxLength="100"
              type="email"
              value={feedbackForm.email}
              onChange={(e) => setfeedbackForm({ ...feedbackForm, email: e.target.value })}
            /></label>

          <button
            className={styles.sendButton}
            onClick={submitFeedback}>{buttonLabel}</button>

          <button
            className={styles.closeButton}
            onClick={handleButton}>close</button>

        </label>
        :
        <button
          onClick={handleButton}
          className={styles.menuButton1} >
          Your Voice
        </button>
      }

    </div >
  )
}